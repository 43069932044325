import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  AlertController,
  LoadingController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { TermsComponent } from 'src/app/components/terms/terms.component';
import { VerifyComponent } from 'src/app/components/verify/verify.component';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { SpaceValidator } from 'src/app/utilities/spaceValidator';
import { environment } from 'src/environments/environment';
import { AboutPage } from '../about/about.page';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  isGeneralVersion: boolean = environment.isGeneralVersion;
  title: String = 'INICIAR SESION';
  type: String = 'login';
  countryList: any = [];
  sexList: any = [
    { id: 'M', nombre: 'Masculino' },
    { id: 'F', nombre: 'Femenino' },
    { id: 'N', nombre: 'No Binario' },
  ];
  objectives: any = [
    { id: '2', nombre: 'Perder grasa' },
    { id: '1', nombre: 'Ganar masa muscular' },
    { id: '3', nombre: 'Mejorar salud' },
  ];
  plans: any = [{ id: '1', nombre: 'Mensual General' }];
  paymentTypes: any = [
    { id: '1', nombre: 'Periodo de prueba' },
    { id: '2', nombre: 'Plan de pago' },
  ];
  customPickerOptions: any;
  metodo: any;
  loginForm = {
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(8)]],
  };
  registerForm = {
    name: ['', [Validators.required]],
    email: [
      '',
      [Validators.required, Validators.email, SpaceValidator.noWhiteSpace],
    ],
    password: ['', [Validators.required, Validators.minLength(8)]],
    password_confirmation: ['', [Validators.required, Validators.minLength(8)]],
    sexo: ['', [Validators.required]],
    peso: ['', [Validators.required]],
    estatura: ['', [Validators.required]],
    objetivo: ['', [Validators.required]],
    telefono: ['', [Validators.pattern('^[0-9]*$'), Validators.required]],
    area: ['', [Validators.required]],
    avatar: ['./assets/images/default.png'],
    terms: [false, [Validators.requiredTrue]],
  };
  recoverForm = {
    email: ['', [Validators.required, Validators.email]],
  };
  changeForm = {
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(8)]],
    password_confirmation: ['', [Validators.required, Validators.minLength(8)]],
    token: ['', [Validators.required]],
  };
  credentials: FormGroup;

  constructor(
    private fb: FormBuilder,
    private alertController: AlertController,
    private router: Router,
    private loadingController: LoadingController,
    private authService: AuthService,
    public toastController: ToastController,
    public userService: UserService,
    public modalController: ModalController
  ) {
    this.customPickerOptions = {
      buttons: [
        {
          text: 'Cancelar',
          handler: () => {
            console.log('Clicked Log. Do not Dismiss.');
            return false;
          },
        },
        {
          text: 'Guardar',
          handler: (date) => {
            this.credentials.patchValue({
              fecha_nacimiento: `${date.year.text}-${date.month.text}-${date.day.text}`,
            });
          },
        },
      ],
    };
  }

  async ngOnInit() {
    if (this.type === 'login') {
      this.credentials = this.fb.group(this.loginForm);
    } else if (this.type === 'register') {
      this.credentials = this.fb.group(this.registerForm);
    } else if (this.type === 'recover') {
      this.credentials = this.fb.group(this.recoverForm);
    } else {
      this.credentials = this.fb.group(this.changeForm);
    }
    const countries = await this.userService.getStorageCountries();

    if (countries) {
      this.countryList = countries;
    } else {
      await this.getCountrys();
    }
    // this.getCountrys();
  }
  async getCountrys() {
    const loading = await this.loadingController.create();
    await loading.present();
    this.authService.country$.subscribe(
      (countrys) => {
        if (countrys) {
          this.countryList = countrys;
          this.countryList.sort((a, b) => {
            return a.nombre.localeCompare(b.nombre);
          });
        }
        this.userService.saveContries(this.countryList);
        this.loadingController.dismiss();
      },
      (err) => {
        this.loadingController.dismiss();
      }
    );
  }
  changeFormType(type) {
    this.type = type;

    if (type === 'login') {
      this.credentials = this.fb.group(this.loginForm);
    } else if (type === 'register') {
      this.credentials = this.fb.group(this.registerForm);
    } else if (type === 'recover') {
      this.credentials = this.fb.group(this.recoverForm);
    } else if (type === 'change') {
      this.credentials = this.fb.group(this.changeForm);
    } else {
      this.credentials = this.fb.group(this.recoverForm);
    }
  }
  handleSubmit() {
    switch (this.type) {
      case 'login':
        this.login();
        break;
      case 'recover':
        this.passwordRecover();
        break;
      case 'change':
        this.changePassword();
        break;
      case 'register':
        this.userRegister(this.credentials.value);
        break;
      case 'verify':
        this.resendCode();
        break;
      default:
        break;
    }
  }
  numericOnly(event): boolean {
    let pattern = /^([0-9])$/;
    let result = pattern.test(event.key);
    return result;
  }
  async openAbout(page) {
    const modal = await this.modalController.create({
      component: TermsComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        initialPage: page,
      },
    });
    modal.onDidDismiss().then(async (data) => {
      console.log(data);
    });
    return await modal.present();
  }
  async login() {
    const loading = await this.loadingController.create();
    await loading.present();
    let body = {...this.credentials.value};
    body.app = environment.isGeneralVersion ? '1' : '2';
    this.authService.login(body).subscribe(
      async (res) => {
        await loading.dismiss();
        this.router.navigateByUrl('/tabs', { replaceUrl: true });
      },
      async (err) => {
        console.log(err);
        if (err.status == 0) {
          const alert = await this.alertController.create({
            header: 'Error al iniciar sesión',
            message: 'Verifique su conexión a internet',
            buttons: ['OK'],
          });

          await alert.present();
        } else{
          const mensaje = err.error.errors ? err.error.errors : err.error.motives;
          const alert = await this.alertController.create({
            header: 'Error al iniciar sesión',
            message: mensaje?.message || '¡Ha ocurrido un error, intente de nuevo más tardes!',
            buttons:
              err.status == 422 && err.error?.motives?.status  == 1
                ? [
                    {
                      text: 'Ok',
                      handler: () => {
                        this.openVerify(this.credentials.value);
                      },
                    },
                  ]
                : ['OK']
          });
          await alert.present();
        }
        await loading.dismiss();

      }
    );
  }
  async resendCode() {
    const loading = await this.loadingController.create();
    await loading.present();
    this.authService
      .resendCode({ email: this.email.value, send_verification: 1 })
      .subscribe(
        async (res: any) => {
          await loading.dismiss();
          const alert = await this.alertController.create({
            header: 'Registro de atleta',
            message:
              'Se ha enviado un código a su correo para verificar su cuenta',
            buttons: [
              {
                text: 'Ok',
                handler: () => {
                  this.openVerify(this.credentials.value);
                },
              },
            ],
          });
          await alert.present();
        },
        async (err: any) => {
          console.log(err);
          await loading.dismiss();

          const mensaje = err.error.errors
            ? err.error.errors
            : err.error.motives;
          for (let item in mensaje) {
            const alert = await this.alertController.create({
              header: 'Error al verificar',
              message: mensaje[item],
              buttons: ['OK'],
            });

            await alert.present();
          }
        }
      );
  }
  async passwordRecover() {
    const loading = await this.loadingController.create();
    await loading.present();

    this.authService.forgotPassword(this.credentials.value).subscribe(
      async (res) => {
        await loading.dismiss();
        const alert = await this.alertController.create({
          header: 'Cambiar contraseña',
          message: 'Se envió un código de verificación a su correo electrónico',
          buttons: [
            {
              text: 'Ok',
              handler: () => {
                this.changeFormType('change');
              },
            },
          ],
        });
        await alert.present();
      },
      async (error) => {
        await loading.dismiss();
        const alert = await this.alertController.create({
          header: 'Error al recuperar contraseña',
          message: 'No se ha encontrado esta dirección de correo electrónico',
          buttons: ['OK'],
        });

        await alert.present();
      }
    );
  }
  async changePassword() {
    if (this.password.value === this.password_confirmation.value) {
      const loading = await this.loadingController.create();
      await loading.present();

      this.authService.changePassword(this.credentials.value).subscribe(
        async (res) => {
          await loading.dismiss();
          const alert = await this.alertController.create({
            header: 'Cambiar contraseña',
            message: 'La contraseña se cambio con éxito',
            buttons: [
              {
                text: 'Ok',
                handler: () => {
                  this.changeFormType('login');
                },
              },
            ],
          });
          await alert.present();
        },
        async (error) => {
          await loading.dismiss();

          const alert = await this.alertController.create({
            header: 'Error al cambiar contraseña',
            message: error.error.errors.email
              ? error.error.errors.email
              : 'Error al cambiar contraseña',
            buttons: ['OK'],
          });

          await alert.present();
        }
      );
    } else {
      const alert = await this.alertController.create({
        header: 'Error al cambiar contraseña',
        message: 'Contraseña de confirmación no coincide',
        buttons: ['OK'],
      });

      await alert.present();
    }
  }

  async userRegister(data) {
    const body = this.createRegisterBody(data);
    const bodyLogin = {
      email: data.email,
      password: data.password,
      remember: 0,
    };

    if (this.password.value === this.password_confirmation.value) {
      const loading = await this.loadingController.create();
      await loading.present();

      this.userService.userRegister(body).subscribe(
        async (res: any) => {
          console.log(res);

          await loading.dismiss();
          const alert = await this.alertController.create({
            header: 'Registro de atleta',
            message:
              'Para completar el registro verifique su correo electronico',
            buttons: [
              {
                text: 'Ok',
                handler: () => {

                  this.openVerify(bodyLogin);
                },
              },
            ],
          });
          await alert.present();
        },
        async (error) => {
          await loading.dismiss();
          console.log(error);

          const alert = await this.alertController.create({
            header: 'Error al registrar atleta',
            message: error.error.errors.email
              ? error.error.errors.email
              : 'Error al registrar atleta',
            buttons: ['OK'],
          });

          await alert.present();
        }
      );
    } else {
      const alert = await this.alertController.create({
        header: 'Error al registrar atleta',
        message: 'Contraseña de confirmación no coincide',
        buttons: ['OK'],
      });

      await alert.present();
    }
  }
  createRegisterBody(data) {
    const body = {
      name: data.name,
      email: data.email,
      password: data.password,
      password_confirmation: data.password_confirmation,
      sexo: data.sexo,
      telefono: data.area.codigo + data.telefono,
      pais_id: data.area.pais_id,
      objetivo_id: data.objetivo,
      plan_id: data.plan,
    };

    return body;
  }
  async openVerify(data?) {
    const modal = await this.modalController.create({
      component: VerifyComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        userData: data,
      },
    });
    modal.onDidDismiss().then(async (resp) => {
      const { data } = resp;
      if (data.event == 'success') {
        this.changeFormType('login');
      }
    });
    return await modal.present();
  }
  async presentToast() {
    const toast = await this.toastController.create({
      message: '',
      duration: 2000,
    });
    toast.present();
  }
  //getters form fields
  get email() {
    return this.credentials.get('email');
  }

  get password() {
    return this.credentials.get('password');
  }
  get password_confirmation() {
    return this.credentials.get('password_confirmation');
  }
  get name() {
    return this.credentials.get('name');
  }
  get token() {
    return this.credentials.get('token');
  }
  get pais_id() {
    return this.credentials.get('pais_id');
  }
  get telefono() {
    return this.credentials.get('telefono');
  }
  get area() {
    return this.credentials.get('area');
  }
  get sexo() {
    return this.credentials.get('sexo');
  }
  get fecha_nacimiento() {
    return this.credentials.get('fecha_nacimiento');
  }
  get peso() {
    return this.credentials.get('peso');
  }
  get estatura() {
    return this.credentials.get('estatura');
  }
  get objetivo() {
    return this.credentials.get('objetivo');
  }
  get plan() {
    return this.credentials.get('plan');
  }
  get tipo_pago() {
    return this.credentials.get('tipo_pago');
  }
}
