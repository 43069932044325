import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-video-popover',
  templateUrl: './video-popover.component.html',
  styleUrls: ['./video-popover.component.scss'],
})
export class VideoPopoverComponent implements OnInit {
  @Input() videoUrl: any;
  @Input() youtube: boolean = false;
  isLoading: boolean = true;
  constructor(
    private popoverController: PopoverController,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {}
  async dismiss() {
    await this.popoverController.dismiss('close');
  }
  load() {

    this.isLoading = false;
  }

}
