import { Component, OnInit } from '@angular/core';
import {
  AlertController,
  LoadingController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
})
export class VerifyComponent implements OnInit {
  verifyForm: FormGroup;
  userData: any;
  constructor(
    private fb: FormBuilder,
    private alertController: AlertController,
    private router: Router,
    private loadingController: LoadingController,
    private authService: AuthService,
    public toastController: ToastController,
    public userService: UserService,
    public modalController: ModalController
  ) {
    this.verifyForm = this.fb.group({ codigo: ['', [Validators.required]] });
  }

  ngOnInit() {
  }
  dismiss(event) {
    this.modalController.dismiss({
      event,
    });
  }
  async handleSubmit() {
    const loading = await this.loadingController.create();
    await loading.present();
    this.authService
      .verifyEmail({ email: this.userData.email, codigo: this.code.value })
      .subscribe(
        async (res: any) => {
          await loading.dismiss();
          const alert = await this.alertController.create({
            header: 'Registro de atleta',
            message: 'Se ha verificado su correo ',
            buttons: [
              {
                text: 'Ok',
                handler: () => {
                  if (this.userData.password) {
                    this.authService.login(this.userData).subscribe(
                      (res) => {
                        this.router.navigateByUrl('/welcome', {
                          replaceUrl: true,
                        });
                        this.dismiss('success');
                      },
                      (err) => {
                        console.log(err);
                      }
                    );
                  } else {
                    this.dismiss('success');
                  }
                },
              },
            ],
          });
          await alert.present();
        },
        async (err: any) => {
          console.log(err);
          await loading.dismiss();

          const mensaje = err.error.errors
            ? err.error.errors
            : err.error.motives;
          for (let item in mensaje) {
            const alert = await this.alertController.create({
              header: 'Error al verificar',
              message: mensaje[item],
              buttons: ['OK'],
            });

            await alert.present();
          }
        }
      );
  }
  async resendCode() {
    const loading = await this.loadingController.create();
    await loading.present();
    this.authService
      .resendCode({ email: this.userData.email, send_verification: 1 })
      .subscribe(
        async (res: any) => {
          await loading.dismiss();
          const alert = await this.alertController.create({
            header: 'Registro de atleta',
            message:
              'Se ha enviado un código a su correo para verificar su cuenta ',
            buttons: [
              {
                text: 'Ok',
                handler: () => {},
              },
            ],
          });
          await alert.present();
        },
        async (err: any) => {
          console.log(err);
          await loading.dismiss();

          const mensaje = err.error.errors
            ? err.error.errors
            : err.error.motives;
          for (let item in mensaje) {
            const alert = await this.alertController.create({
              header: 'Error al verificar',
              message: mensaje[item],
              buttons: ['OK'],
            });

            await alert.present();
          }
        }
      );
  }
  get code() {
    return this.verifyForm.controls.codigo;
  }
}
