import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StoreService } from '../services/store.service';

const USER_KEY = environment.USER_KEY;

@Injectable({
  providedIn: 'root',
})
export class SubCheckGuard implements CanLoad {
  constructor(private router: Router, private store:StoreService) {

  }
  async init() {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    await this.store.init();
    await this.store.openStore("");
  }
  async canLoad(): Promise<boolean> {
    await this.init();
    const user = await this.store.getItem(USER_KEY);
    const JSONuser = JSON.parse(user);

    const trialExpired = JSONuser.settings.trial;
    const subExpired = JSONuser.suscripcion;

    if ( (environment.isGeneralVersion && (
      (trialExpired && trialExpired.active === true) ||
      (subExpired && subExpired.activo === true) ))|| !environment.isGeneralVersion
    ) {
      return true;
    } else {
      this.router.navigateByUrl('/sub-check', { replaceUrl: true });
      return false;
    }
  }
}
