import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slides',
  templateUrl: './slides.component.html',
  styleUrls: ['./slides.component.scss'],
})
export class SlidesComponent implements OnInit {
  slideOpts = {
    initialSlide: 0,
    speed: 400,
    slidesPerView: 3,
  };
  trainingList: any = [];
  constructor() {}

  ngOnInit() {
    this.getTraining();
  }
  getTraining() {
    this.trainingList = [
      {
        title: 'Sin equipo',
        imgUrl: './assets/images/sinequipo.jpg',
      },
      {
        title: 'GYM',
        imgUrl: './assets/images/gym.jpg',
      },
      {
        title: 'Mancuerna',
        imgUrl: './assets/images/mancuerna.jpg',
      },
      {
        title: 'Crossfit',
        imgUrl: './assets/images/crossfit.jpeg',
      },
      {
        title: 'Calistenia',
        imgUrl: './assets/images/sinequipo.jpg',
      },
    ];
  }
}
