import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  AlertController,
  LoadingController,
  ModalController,
  PopoverController,
} from '@ionic/angular';
import { PopoverComponent } from 'src/app/components/popover/popover.component';
import { AuthService } from 'src/app/services/auth.service';
import {
  Camera,
  CameraResultType,
  CameraSource,
  Photo,
} from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { PaymentService } from 'src/app/services/payment.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import * as moment from 'moment';
import { StoreService } from 'src/app/services/store.service';
const USER_KEY = environment.USER_KEY;
@Component({
  selector: 'app-profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss'],
})
export class ProfilePage implements OnInit {
  isGeneralVersion = environment.isGeneralVersion;
  @Input() segment: string = 'datos';
  credentials: FormGroup;
  realizedPayments: any = [];
  isLoading: boolean = true;
  profilePic;
  calendarExpanded: boolean = false;
  expDate: any;
  isExpired: boolean = false;
  countryList: any = [];
  sexList: any = [
    { id: 'M', nombre: 'Masculino' },
    { id: 'F', nombre: 'Femenino' },
    { id: 'N', nombre: 'No Binario' },
  ];
  customPickerOptions: any;

  isReload: boolean = false;

  constructor(
    private fb: FormBuilder,
    private alertController: AlertController,
    private router: Router,
    private loadingController: LoadingController,
    private authService: AuthService,
    public modalController: ModalController,
    public popoverController: PopoverController,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    private paymentService: PaymentService,
    private iab: InAppBrowser,
    private store:StoreService
  ) {
    // this.profilePic = authService.user ? authService.user.avatar : '';
    this.createForm();

    this.customPickerOptions = {
      buttons: [
        {
          text: 'Cancelar',
          handler: () => {
            console.log('Clicked Log. Do not Dismiss.');
            return false;
          },
        },
        {
          text: 'Guardar',
          handler: (date) => {
            this.credentials.patchValue({
              fecha_nacimiento: `${date.year.text}-${date.month.text}-${date.day.text}`,
            });
          },
        },
      ],
    };
  }

  async ngOnInit() {
    const loading = await this.loadingController.create();
    await loading.present();
    const countries = await this.userService.getStorageCountries();

    if (countries) {
      this.countryList = countries;
    } else {
      await this.getCountrys();
    }
    const user = await this.authService.getUser();
    if (user) {
      await loading.dismiss();
      this.patchForm(user);
    } else {
      this.getCurrentUser(loading, false);
    }
    this.getCurrentUser(loading, true);

    // this.getCountrys();

    this.getPlans();
  }
  getPlans() {
    this.isLoading = true;
    this.paymentService.getPayments().subscribe(
      (res: any) => {
        this.realizedPayments = res.data;
        this.realizedPayments.forEach(payment => {
          payment.fixed=moment.utc(payment.fecha_pago).local().format('DD/MM/YYYY, h:mm a');
      });

        this.isLoading = false;
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }
  async getCurrentUser(loading, silent?) {
    !silent ?? (await loading.present());

    return this.userService.getUser().subscribe(
      async (res: any) => {
        res.data.avatar = await this.loadImage(
          res.data.avatar + '?' + new Date().getTime()
        );
        this.authService.changeUser(res.data);
        this.patchForm(res.data);
        this.expDate = new Date(res.data.settings.trial.left_time_seconds);
        this.isExpired = res.data.settings.trial.active;
        await loading.dismiss();
      },
      (err) => {
        console.log(err);
      }
    );
  }
  async loadImage(imageUrl) {
    let img;
    const imageLoadPromise = new Promise((resolve) => {
      img = new Image();
      img.onload = resolve;
      img.src = imageUrl;
    });

    await imageLoadPromise;
    return img.src;
  }
  async changeAvatar() {
    Camera.getPhoto({
      resultType: CameraResultType.Base64,
      source: CameraSource.Prompt,
      quality: 100,
      promptLabelHeader: 'Cambiar foto',
      promptLabelPhoto: 'Elegir una imagen',
      promptLabelPicture: 'Tomar foto',
      width: 250,
      height: 250,
    }).then(async (capturedPhoto) => {
      const profilePic = `data:image/${capturedPhoto.format};base64, ${capturedPhoto.base64String}`;
      const sanitizedPic = await this.sanitize(profilePic);
      this.credentials.get('avatar').patchValue(sanitizedPic);
      this.profilePic = this.sanitize(profilePic);
    }).catch((e) => console.warn('Error camara getPhoto'));
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  patchForm(data) {
    this.credentials.patchValue({
      name: data.name ? data.name : '',
      email: data.email ? data.email : '',
      fecha_nacimiento: data.fecha_nacimiento ? data.fecha_nacimiento : '',
      pais_id: data.pais ? data.pais.id.toString() : '',
      sexo: data.sexo ? data.sexo : '',
      tiempo_disponible: data.tiempo_disponible ? data.tiempo_disponible : '',
      telefono: data.telefono ? data.telefono : '',
      avatar: data.avatar ? data.avatar : '',
    });
  }
  createForm() {
    this.credentials = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      fecha_nacimiento: [''],
      pais_id: [''],
      sexo: [''],
      tiempo_disponible: ['', [Validators.pattern('^[0-9]*$')]],
      telefono: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      avatar: ['./assets/images/default.png'],
    });
  }
  numericOnly(event): boolean {
    let pattern = /^([0-9])$/;
    let result = pattern.test(event.key);
    return result;
  }
  async edit() {
    const body = this.makeBodyEditUser(this.credentials.value);
    const loading = await this.loadingController.create();
    await loading.present();

    this.userService.editUser(body).subscribe(
      async (res: any) => {
        await loading.dismiss();
        const alert = await this.alertController.create({
          header: 'Editar usuario',
          message: 'Se editaron los datos del usuario',
          buttons: [
            {
              text: 'Ok',
              handler: () => {
                res.data.avatar = res.data.avatar + '?' + new Date().getTime();
                this.store.setItem(USER_KEY, JSON.stringify(res.data));
                this.authService.changeUser(res.data);
              },
            },
          ],
        });
        await alert.present();
      },
      async (err) => {
        console.log('ERROR EDITAR USUARIO', err);
        await loading.dismiss();
        const alert = await this.alertController.create({
          header: 'Error al editar usuario',
          message: err.error.errors.email
            ? err.error.errors.email
            : 'Hubo un error al editar usuario',
          buttons: ['OK'],
        });

        await alert.present();
      }
    );
  }
  makeBodyEditUser(formValue) {
    const body = {
      name: formValue.name,
      sexo: formValue.sexo,
      email: formValue.email,
      fecha_nacimiento: formValue.fecha_nacimiento,
      telefono: formValue.telefono,
      tiempo_disponible: formValue.tiempo_disponible,
      pais_id: formValue.pais_id,
      avatar: formValue.avatar.changingThisBreaksApplicationSecurity
        ? formValue.avatar.changingThisBreaksApplicationSecurity
        : formValue.avatar,
    };

    if (!body.avatar.startsWith('data:')) {
      delete body.avatar;
    }
    console.log(body);

    return body;
  }
  isBase64(str) {
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  }
  expandCalendar() {
    this.calendarExpanded = !this.calendarExpanded;
  }
  segmentChanged(ev: any) {
    this.segment = ev;
  }
  async logout() {
    this.dismiss('logout');


  }

  async getCountrys() {
    this.userService.getCountrys().subscribe(
      async (res: any) => {
        this.countryList = res.data;

        this.countryList.sort((a, b) => {
          return a.nombre.localeCompare(b.nombre);
        });
        this.userService.saveContries(this.countryList);
      },
      async (err) => {
        console.log(err);
      }
    );
  }
  async reloadProfile(ev) {
    const loading = await this.loadingController.create();
    await loading.present();
    if (ev === true) {
      this.getCurrentUser(loading, true);
    }
  }
  async menuProfileOpen(ev: any) {
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      cssClass: 'menu',
      mode: 'md',
      showBackdrop: false,
      event: ev,
      translucent: true,
    });
    await popover.present();

    const role = await popover.onDidDismiss();
    switch (role.data) {
      case 'logout':
        this.logout();
        break;

      default:
        break;
    }
  }

  async dismiss(type?) {
    const closeModal: string = type;
    await this.modalController.dismiss(closeModal);
  }
  //getters form fields
  get name() {
    return this.credentials.get('name');
  }
  get email() {
    return this.credentials.get('email');
  }
  get password() {
    return this.credentials.get('password');
  }
  get fecha_nacimiento() {
    return this.credentials.get('fecha_nacimiento');
  }
  get pais_id() {
    return this.credentials.get('pais_id');
  }
  get sexo() {
    return this.credentials.get('sexo');
  }
  get tiempo_disponible() {
    return this.credentials.get('tiempo_disponible');
  }
  get telefono() {
    return this.credentials.get('telefono');
  }
  get avatar() {
    return this.credentials.get('avatar');
  }
}
