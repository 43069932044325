import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  ModalController,
  Platform,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { PaymentService } from 'src/app/services/payment.service';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { AppAvailability } from '@ionic-native/app-availability/ngx';
import { UserService } from 'src/app/services/user.service';
import { PlansService } from 'src/app/services/plans.service';
import { paymentMethodTransbank, paymentMethodFlow } from 'src/environments/hostings';
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  paymentForm: FormGroup;
  paymentUrl: String = '';
  subsList: any = [];
  isLoading: boolean = false;
  browser: any;
  waPhone: String = '';

  waText: any;

  constructor(
    private fb: FormBuilder,
    public modalController: ModalController,
    public popoverController: PopoverController,
    private paymentService: PaymentService,
    private plansService: PlansService,
    private iab: InAppBrowser,
    private toast: ToastController,
    private platform: Platform,
    private userService: UserService,
    private appAvailability: AppAvailability
  ) {}

  async ngOnInit() {
    this.paymentForm = this.fb.group({
      plan_id: ['', [Validators.required]],
    });
    this.getSubs();

    window.addEventListener('message', (event: any) => {
      if (event.origin == environment.URL) {
        const data = JSON.parse(event.data);
        const status = environment.paymentMethod = paymentMethodTransbank ? data.status : data.response.status;
        this.showToast(status, environment.paymentMethod);
        this.dismiss('payment');
        if (this.browser) {
          this.browser.close();
        }
      }
    });
  }
  getSubs() {
    this.paymentService.getSubsPlans(1).subscribe(
      (res: any) => {
        this.subsList = res.data;
      },
      (err: any) => {}
    );
  }
  showToast(status, paymentMethod) {
    let texto_estatus = '';
    let type = '';
    if (paymentMethod == paymentMethodTransbank) {
      switch (status) {
        case 0:
          texto_estatus =
            'Su pago ha sido procesado exitosamente. \nGRACIAS POR SU PAGO';
          type = 'success';
        break;
        case -1:
          texto_estatus ='Rechazado - Posible error en el ingreso de datos de la transacción';
          type = 'warning';
        break;
        case -2:
          texto_estatus =	'Rechazado - Se produjo fallo al procesar la transacción. \nEl problema puede estar relacionado a los datos suministrados de la tarjeta y/o su cuenta asociada';
          type = 'warning';
        break;
        case -3:
          texto_estatus =	'Rechazado - Error en Transacción';
          type = 'danger';
        break;
        case -4:
          texto_estatus =	'Rechazada por parte del emisor';
          type = 'danger';
        break;
        case -5:
          texto_estatus =	'Rechazada - Transacción con riesgo de posible fraude';
          type = 'danger';
        break;
        default:
          texto_estatus = 'No se ha podido completar el pago.';
          type = 'danger';
        break;
      }
    } else if(paymentMethod == paymentMethodFlow) {
      switch (status) {
        case 0:
          texto_estatus =
            'No se ha podido completar el pago. \nIntente de nuevo!';
          type = 'warning';
          break;
        case 1:
          texto_estatus =
            'No se ha podido completar el pago. El estatus de su pago es : "Pendiente de pago"';
          type = 'warning';
          break;
        case 2:
          texto_estatus =
            'Su pago ha sido procesado exitosamente. \nGRACIAS POR SU PAGO';
          type = 'success';
          break;
        case 3:
          texto_estatus = 'Su pago ha sido rechazado. \nIntente de nuevo!';
          type = 'danger';
          break;
        case 4:
          texto_estatus = 'Su pago ha sido anulado. \nIntente de nuevo!';
          type = 'danger';
          break;
        default:
          texto_estatus = 'No se ha podido completar el pago.. \nIntente más tarde!';
          type = 'warning';
          break;
      }
    }

    this.presentToast(texto_estatus, type);
  }

  addPayment() {
    this.isLoading = true;
    this.paymentService.registerPayment(this.paymentForm.value).subscribe(
      (res: any) => {
        const { data } = res;
        const body: any = {
          email: data.email,
          amount: Number(data.plan.precio),
          subject: data.plan.nombre,
        };
        if (environment.paymentMethod == paymentMethodFlow){
          body.appweb  = !this.platform.is('capacitor') ?  '1' : '0';
          this.paymentService.flow(body).subscribe(
            (res: any) => {
              const { data } = res;
              this.isLoading = false;
              if(!this.platform.is('capacitor')){
                window.location.href = data.urlRedirect;
              } else if (this.platform.is('ios') && this.platform.is('capacitor')) {
                this.browser = this.iab.create(data.urlRedirect, '_blank');
                this.browser.on('exit').subscribe((e) => {
                  this.dismiss('payment');
                });

                this.browser.on('loadstop').subscribe((e) => {
                  if (e.url.indexOf(environment.URL + '/api/flow/return') > -1) {
                    const $this = this;
                    setTimeout(function () {
                      $this.browser.close();
                    }, 3000);
                  }
                });
              } else {
                var isSafari =
                  /Safari/.test(navigator.userAgent) &&
                  /Apple Computer/.test(navigator.vendor);
                if (isSafari) {
                  this.browser = window.open(data.urlRedirect, '_blank');
                  try {
                    this.browser.focus();
                  } catch (e) {
                    this.presentToast(
                      '¡El bloqueador de elementos emergentes está habilitado! Desactive su bloqueador de ventanas emergentes.',
                      'warning'
                    );
                  }
                } else {
                  this.paymentUrl = data.urlRedirect;
                }
              }
            },
            (err: any) => {
              console.log(err);
              this.isLoading = false;
            }
          );
        } else if(environment.paymentMethod == paymentMethodTransbank) {
          this.paymentService.transbank(body).subscribe(
            (res: any) => {
              const { data } = res;
              this.isLoading = false;
              if (this.platform.is('ios') && this.platform.is('capacitor')) {
                this.browser = this.iab.create(data.urlRedirect, '_blank');

                this.browser.on('exit').subscribe((e) => {
                  this.dismiss('payment');
                });

                this.browser.on('loadstop').subscribe((e) => {
                  if (e.url.indexOf(environment.URL + '/api/flow/return') > -1) {
                    const $this = this;
                    setTimeout(function () {
                      $this.browser.close();
                    }, 3000);
                  }
                });
              } else {
                var isSafari =
                  /Safari/.test(navigator.userAgent) &&
                  /Apple Computer/.test(navigator.vendor);
                if (isSafari) {
                  this.browser = window.open(data.urlRedirect, '_blank');
                  try {
                    this.browser.focus();
                  } catch (e) {
                    this.presentToast(
                      '¡El bloqueador de elementos emergentes está habilitado! Desactive su bloqueador de ventanas emergentes.',
                      'warning'
                    );
                  }
                } else {
                  this.paymentUrl = data.urlRedirect;
                }
              }
            },
            (err: any) => {
              console.log(err);
              this.isLoading = false;
            }
          );
        }

      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  async dismiss(payment?) {
    const closeModal: string = payment ? payment : 'Modal Closed';
    await this.modalController.dismiss(closeModal);
  }
  async presentToast(msg, type) {
    const toast = await this.toast.create({
      message: msg,
      duration: 2000,
      color: type,
      position: 'top',
    });
    toast.present();
  }
}
