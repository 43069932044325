// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { env } from './env';
import { project } from './env.theme';
export const firebaseKeys = env.firebaseConfig;
export const environment = {
  production: false,
  isDebugMode: true,
  project,
  oneSignalAppId: env.oneSignalAppId,
  googleProjectNumber: env.project_number,
  URL: env.local_api,
  TOKEN_KEY: env.storageToken,
  USER_KEY: env.storageUserKey,
  PLANS_KEY: env.storagePlans,
  TEC_KEY: env.storageTecniques,
  COUNTRIES_KEY: env.storageCountries,
  SETTINGS_KEY: env.storageSettings,
  PARAMS_KEY: env.storageParameters,
  REINF_KEY: env.storageReinforcements,
  paymentMethod :  env.paymentMethod,
  isGeneralVersion: env.isGeneralVersion
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
