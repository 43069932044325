export const paymentMethodTransbank = 'transbank';
export const paymentMethodFlow = 'flow';
export const hostings: any = {
  local: {
    paymentMethod : paymentMethodTransbank,
    storageToken: 'my-local-token',
    storageUserKey: 'user-local-key',
    storagePlans: 'storage-plans-local-key',
    storageTecniques: 'storage-tech-local-key',
    storageCountries: 'storage-countries-local-key',
    storageSettings: 'storage-settings-local-key',
    storageParameters: 'storage-parameters-local-key',
    storageReinforcements: 'storage-reinforcements-local-key',
    local_api: 'https://streetgym.test',
    prod_api: 'http://localhost:8000',
    project_number: '392359443372',
    oneSignalAppId: '6f659493-9765-4001-ac7d-d61d52dc005d',
    firebaseConfig: {
      apiKey: 'AIzaSyA7_6AZPEjFrmFaQPRa8aK30b4kXMeiaFE',
      authDomain: 'streetgym-21dd9.firebaseapp.com',
      projectId: 'streetgym-21dd9',
      storageBucket: 'streetgym-21dd9.appspot.com',
      messagingSenderId: '392359443372',
      appId: '1:392359443372:web:7e81b66a3fd046c2a61809',
      measurementId: 'G-X5E4QVRBCT',
    },
    isGeneralVersion: false
  },
  dev: {
    paymentMethod : paymentMethodTransbank,
    storageToken: 'my-dev-token',
    storageUserKey: 'user-dev-key',
    storagePlans: 'storage-plans-dev-key',
    storageTecniques: 'storage-tech-dev-key',
    storageCountries: 'storage-countries-dev-key',
    storageSettings: 'storage-settings-dev-key',
    storageParameters: 'storage-parameters-dev-key',
    storageReinforcements: 'storage-reinforcements-dev-key',
    local_api: 'https://dev.panel.streetgymprogram.com',
    prod_api: 'https://panel.streetgymprogram.com',
    project_number: '392359443372',
    oneSignalAppId: '6f659493-9765-4001-ac7d-d61d52dc005d',
    firebaseConfig: {
      apiKey: 'AIzaSyA7_6AZPEjFrmFaQPRa8aK30b4kXMeiaFE',
      authDomain: 'streetgym-21dd9.firebaseapp.com',
      projectId: 'streetgym-21dd9',
      storageBucket: 'streetgym-21dd9.appspot.com',
      messagingSenderId: '392359443372',
      appId: '1:392359443372:web:7e81b66a3fd046c2a61809',
      measurementId: 'G-X5E4QVRBCT',
    },
    isGeneralVersion: true
  },
  general: {
    paymentMethod : paymentMethodFlow,
    storageToken: 'my-general-token',
    storageUserKey: 'user-general-key',
    storagePlans: 'storage-plans-general-key',
    storageTecniques: 'storage-tech-general-key',
    storageCountries: 'storage-countries-general-key',
    storageSettings: 'storage-settings-general-key',
    storageParameters: 'storage-parameters-general-key',
    storageReinforcements: 'storage-reinforcements-general-key',
    local_api: 'https://dev.panel.streetgymprogram.com',
    prod_api: 'https://panel.streetgymprogram.com',
    project_number: '392359443372',
    oneSignalAppId: '255ce78a-d515-4aa0-a481-8a2830779576',
    firebaseConfig: {
      apiKey: 'AIzaSyA7_6AZPEjFrmFaQPRa8aK30b4kXMeiaFE',
      authDomain: 'streetgym-21dd9.firebaseapp.com',
      projectId: 'streetgym-21dd9',
      storageBucket: 'streetgym-21dd9.appspot.com',
      messagingSenderId: '392359443372',
      appId: '1:392359443372:web:7e81b66a3fd046c2a61809',
      measurementId: 'G-X5E4QVRBCT',
    },
    isGeneralVersion: true
  },
  personal: {
    paymentMethod : paymentMethodTransbank,
    storageToken: 'my-personal-token',
    storageUserKey: 'user-personal-key',
    storagePlans: 'storage-plans-personal-key',
    storageTecniques: 'storage-tech-personal-key',
    storageCountries: 'storage-countries-personal-key',
    storageSettings: 'storage-settings-personal-key',
    storageParameters: 'storage-parameters-personal-key',
    storageReinforcements: 'storage-reinforcements-personal-key',
    local_api: 'https://dev.panel.streetgymprogram.com',
    prod_api: 'https://panel.streetgymprogram.com',
    project_number: '392359443372',
    oneSignalAppId: '021ff7dc-39b9-4cfb-8b64-af9a471d89f7',
    firebaseConfig: {
      apiKey: 'AIzaSyA7_6AZPEjFrmFaQPRa8aK30b4kXMeiaFE',
      authDomain: 'streetgym-21dd9.firebaseapp.com',
      projectId: 'streetgym-21dd9',
      storageBucket: 'streetgym-21dd9.appspot.com',
      messagingSenderId: '392359443372',
      appId: '1:392359443372:web:5cff72a64c6efee8a61809',
      measurementId: 'G-BRCWBP9ZCF',
    },
    isGeneralVersion: false
  },
  vps: {
    paymentMethod : paymentMethodTransbank,
    storageToken: 'my-general-token',
    storageUserKey: 'user-general-key',
    storagePlans: 'storage-plans-general-key',
    storageTecniques: 'storage-tech-general-key',
    storageCountries: 'storage-countries-general-key',
    storageSettings: 'storage-settings-general-key',
    storageParameters: 'storage-parameters-general-key',
    storageReinforcements: 'storage-reinforcements-general-key',
    local_api: 'https://vps.streetgymprogram.com/panel',
    prod_api: 'https://vps.streetgymprogram.com/panel',
    project_number: '392359443372',
    oneSignalAppId: '255ce78a-d515-4aa0-a481-8a2830779576',
    firebaseConfig: {
      apiKey: 'AIzaSyA7_6AZPEjFrmFaQPRa8aK30b4kXMeiaFE',
      authDomain: 'streetgym-21dd9.firebaseapp.com',
      projectId: 'streetgym-21dd9',
      storageBucket: 'streetgym-21dd9.appspot.com',
      messagingSenderId: '392359443372',
      appId: '1:392359443372:web:7e81b66a3fd046c2a61809',
      measurementId: 'G-X5E4QVRBCT',
    },
    isGeneralVersion: true
  },
};
