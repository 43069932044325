import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-video-play',
  templateUrl: './video-play.component.html',
  styleUrls: ['./video-play.component.scss'],
})
export class VideoPlayComponent implements OnInit {
  @ViewChild('video') video: ElementRef;
  isContinue: boolean = false;
  @Input() videoUrl: any;
  @Input() youtube: boolean = false;
  @Input() from: String = '';
  elem: any;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public modalController: ModalController,
    @Inject(DOCUMENT) private document: any
  ) {}

  ngOnInit() {
    this.elem = document.documentElement;
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.changeDetectorRef.detectChanges();
    if (!this.youtube) {
      let elem = this.video.nativeElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      }

      document
        .getElementById('video')
        .addEventListener('fullscreenchange', (event) => {
          // document.fullscreenElement will point to the element that
          // is in fullscreen mode if there is one. If not, the value
          // of the property is null.
          if (document.fullscreenElement) {
            console.log(
              `Element: ${document.fullscreenElement.id} entered fullscreen mode.`
            );
          } else {
            console.log('Leaving full-screen mode.');
            this.dismiss('close');
          }
        });
    }
  }

  async dismiss(from) {
    const closeModal: string = from;
    await this.modalController.dismiss(closeModal);
  }
}
