import { Component, Input, OnInit } from '@angular/core';
import { AppAvailability } from '@ionic-native/app-availability/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ModalController, Platform, PopoverController } from '@ionic/angular';
import { PlansService } from 'src/app/services/plans.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { TermsComponent } from '../terms/terms.component';
import { VideoPlayComponent } from '../video-play/video-play.component';

@Component({
  selector: 'app-wa-button',
  templateUrl: './wa-button.component.html',
  styleUrls: ['./wa-button.component.scss'],
})
export class WaButtonComponent implements OnInit {
  browser: any;
  waPhone: String = '';
  videoUrl: String = '';
  isGeneralVersion = environment.isGeneralVersion;
  instagramUser: String = '';

  waText: any;

  @Input() type: any;

  constructor(
    public modalController: ModalController,
    public popoverController: PopoverController,
    private plansService: PlansService,
    private iab: InAppBrowser,
    private platform: Platform,
    private userService: UserService,
    private appAvailability: AppAvailability
  ) {}

  async ngOnInit() {
    const settings = await this.plansService.getStorageSettings();
    if (settings) {
      this.waPhone = settings.whatsapp_phone;
      this.waText = settings.texto_contacto_whatsapp;
    } else {
      this.getVideo();
    }

    this.getVideo();
  }

  getVideo() {
    this.userService.getSettings().subscribe(
      (res: any) => {
        this.videoUrl = this.isGeneralVersion ?  res.data.welcome_video_url : res.data.welcome_video_url_personal;
        this.waPhone = res.data.whatsapp_phone;
        this.instagramUser = res.data.instagram_account.replace('@', '');
        this.waText = res.data.texto_contacto_whatsapp;
        this.plansService.saveSettings(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
  }
  async openVideo() {
    const modal = await this.modalController.create({
      component: VideoPlayComponent,
      cssClass: 'my-custom-class',
      componentProps: { videoUrl: this.videoUrl, youtube: true, from: 'tabs' },
    });

    // modal.onDidDismiss().then((data) => {

    // });
    return await modal.present();
  }
  async openAbout(page) {
    const modal = await this.modalController.create({
      component: TermsComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        initialPage: page,
      },
    });
    modal.onDidDismiss().then(async (data) => {
      console.log(data);
    });
    return await modal.present();
  }
  socialMedia(type) {
    switch (type) {
      case 'instagram':
        this.openInstagram(this.instagramUser);
        break;
      case 'whatsapp':
        this.openWhatsapp(this.waPhone);
        break;

      default:
        break;
    }
  }
  openInstagram(name) {
    let app;
    if (this.platform.is('ios')) {
      app = 'instagram://';
    } else if (this.platform.is('android')) {
      app = 'com.instagram.android';
    } else {
      this.openInApp('https://www.instagram.com/' + name);
      return;
    }
    this.appAvailability
      .check(app)
      .then((res) => {
        this.openInApp('instagram://user?username=' + name);
      })
      .catch((err) => {
        this.openInApp('https://www.instagram.com/' + name);
      });
  }
  openWhatsapp(number) {
    let app;
    if (this.platform.is('ios')) {
      app = 'whatsapp://';
    } else if (this.platform.is('android')) {
      app = 'com.whatsapp.android';
    } else {
      this.openInApp(
        `https://api.whatsapp.com/send?phone=${number}&text=${
          this.waText ? this.waText : ''
        }`
      );
      return;
    }
    this.appAvailability
      .check(app)
      .then((res) => {
        this.openInApp(
          `whatsapp://send?phone=${number}&text=${
            this.waText ? this.waText : ''
          }`
        );
      })
      .catch((err) => {
        this.openInApp(
          `https://api.whatsapp.com/send?phone=${number}&text=${
            this.waText ? this.waText : ''
          }`
        );
      });
  }
  openInApp(url) {
    this.iab.create(url, '_system');
  }
}
