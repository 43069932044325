import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { StoreService } from './store.service';

const URL = environment.URL;
const PLANS_KEY = environment.PLANS_KEY;
const TEC_KEY = environment.TEC_KEY;
const SETTINGS_KEY = environment.SETTINGS_KEY;
const PARAMS_KEY = environment.PARAMS_KEY;
const REINF_KEY = environment.REINF_KEY;

@Injectable({
  providedIn: 'root',
})
export class PlansService {
  constructor(private http: HttpClient, private store: StoreService) {
    this.init();
  }
  private readonly _Gralplans = new BehaviorSubject(undefined);
  readonly gralPlans = this._Gralplans.asObservable();
  private readonly _Tecniques = new BehaviorSubject(undefined);
  readonly tecniques = this._Tecniques.asObservable();
  private readonly _Settings = new BehaviorSubject(undefined);
  readonly settings = this._Settings.asObservable();
  private readonly _Faqs = new BehaviorSubject(undefined);
  readonly faqs = this._Faqs.asObservable();
  private header(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',

      'X-Requested-With': 'XMLHttpRequest',
    });
  }
  async init() {
    await this.store.init();
    await this.store.openStore("");
  }
  getPersonalPlans() {
    return this.http.get(`${URL}/api/plan-personal`);
  }
  getGralPlans() {
    return this.http.get(`${URL}/api/plan-general/0`);
  }
  getFilteredPlans({ lugar, enfoque, nivel }) {
    var uri = `/api/plan-general/0?nivel_id=${nivel}&lugar_id=${lugar}&enfoque_id=${enfoque}`;
    var res = encodeURI(uri);
    return this.http.get(`${URL}${res}`);
  }
  getReinforcements({ tipo, nivel }) {
    var uri = `/api/rf-semana/0?nivel_refuerzo_id=${nivel}&tipo_refuerzo_id=${tipo}`;
    var res = encodeURI(uri);
    return this.http.get(`${URL}${res}`);
  }
  getTecniques(str, type, page) {
    var uri = `/api/video?search=${str}&tipo_video=${type}&page=${page}`;
    var res = encodeURI(uri);
    return this.http.get(`${URL}${res}`);
  }
  getFeedingPlan(type) {
    var uri = `/api/plan-alimentacion?tipo=${type}`;
    var res = encodeURI(uri);
    return this.http.get(`${URL}${res}`);
  }
  getFAQS() {
    return this.http.get(`${URL}/api/faqs-app`);
  }
  getFAQSPersonal() {
    return this.http.get(`${URL}/api/faqs-app-personal`);
  }
  async savePlans(plans) {
    this._Gralplans.next(plans);
    if (plans) {
      await this.store.setItem(PLANS_KEY, JSON.stringify(plans));
    } else this.store.removeItem(PLANS_KEY);
  }
  async getStoragePlans() {
    const plans = await this.store.getItem(PLANS_KEY);
    return plans ? JSON.parse(plans) : null;
  }
  deleteStoragePlans() {
    return this.store.removeItem(PLANS_KEY);
  }
  saveReinf(reinf) {
    if (reinf) {
      this.store.setItem(REINF_KEY, JSON.stringify(reinf));
    } else this.store.removeItem(REINF_KEY);
  }
  async getStorageReinf() {
    const reinforcements = await this.store.getItem(REINF_KEY);
    return reinforcements ? JSON.parse(reinforcements) : null;
  }
  deleteStorageReinf() {
    return this.store.removeItem(REINF_KEY);
  }
  saveTecniques(tecs) {
    this._Tecniques.next(tecs);

    if (tecs) {
      this.store.setItem(TEC_KEY, JSON.stringify(tecs));
    } else this.store.removeItem(TEC_KEY);
  }
  async getStorageTecniques() {
    const tecniques = await this.store.getItem(TEC_KEY);
    return tecniques ? JSON.parse(tecniques) : null;
  }
  deleteStorageTecniques() {
    return this.store.removeItem(TEC_KEY);
  }
  saveSettings(settings) {
    this._Settings.next(settings);

    if (settings) {
      this.store.setItem(SETTINGS_KEY,JSON.stringify(settings));
    } else this.store.removeItem(SETTINGS_KEY);
  }
  async getStorageSettings() {
    const settings = await this.store.getItem(SETTINGS_KEY);
    return settings ?  JSON.parse(settings) : null;
  }
  deleteStorageSettings() {
    return this.store.removeItem(SETTINGS_KEY);
  }
  saveFAQ(faqs) {
    this._Faqs.next(faqs);
    if (faqs) {
      this.store.setItem(PARAMS_KEY, JSON.stringify(faqs));
    } else this.store.removeItem(PARAMS_KEY);
  }
  async getStorageFAQ() {
    const faqs = await this.store.getItem(PARAMS_KEY);
    return faqs ? JSON.parse(faqs) : null;
  }
  deleteStorageFAQ() {
    return this.store.removeItem(PARAMS_KEY);
  }
}
