import { Component, Renderer2 } from '@angular/core';
import { AuthService } from './services/auth.service';
import { environment } from '../environments/environment';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { Platform } from '@ionic/angular';
import { StatusBar, Style } from '@capacitor/status-bar';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private auth: AuthService,
    renderer: Renderer2,
    private screenOrientation: ScreenOrientation,
    public platform: Platform
  ) {
   // if (environment.project !== 'general') {
    //  renderer.setAttribute(document.body, 'color-theme', 'dark');
   // }

    // get current
    if (this.platform.is('hybrid'))
      this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
  }
}
