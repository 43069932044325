import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { AppAvailability } from '@ionic-native/app-availability/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ModalController, Platform, PopoverController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { PlansService } from 'src/app/services/plans.service';
import { UserService } from 'src/app/services/user.service';
import { VideoPopoverComponent } from '../video-popover/video-popover.component';

@Component({
  selector: 'app-training-description',
  templateUrl: './training-description.component.html',
  styleUrls: ['./training-description.component.scss'],
})
export class TrainingDescriptionComponent implements OnInit {
  @Input() info: any = '';
  @Input() from: any = '';
  @Input() nombre: any = '';
  @Input() lugarImg: any = '';
  title: String = 'Información';
  currentUser: any = {};
  videoUrl: String = '';
  waPhone: String = '';
  instagramUser: String = '';
  anchors: any;
  spans: any;
  p: any;
  waText: any;
  constructor(
    private userService: UserService,
    private plansService: PlansService,
    public modalController: ModalController,
    public authService: AuthService,
    private platform: Platform,
    private inAppBrowser: InAppBrowser,
    private appAvailability: AppAvailability,
    private elementRef: ElementRef,
    public popoverController: PopoverController,

    private changeDetector: ChangeDetectorRef
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
  }
  ngAfterViewInit(): void {
    this.changeDetector.detectChanges();

    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    this.anchors = this.elementRef.nativeElement.querySelectorAll('a');
    // this.spans = this.elementRef.nativeElement.querySelectorAll('span');
    // this.p = this.elementRef.nativeElement.querySelectorAll('p');
    // this.spans.forEach((span) => {
    //   span.removeAttribute('style');

    //   span.classList.add('spanStyle');
    // });
    // this.p.forEach((px) => {
    //   px.classList.add('pStyle');
    // });
    this.anchors.forEach((anchor: HTMLAnchorElement) => {
      anchor.addEventListener('click', (evt) => this.handleAnchorClick(evt));
      // anchor.classList.add('nostyle');
    });

    const detailsTags = this.elementRef.nativeElement.querySelectorAll('details');
    detailsTags.forEach((details: HTMLDetailsElement) => {
      details.removeAttribute('open');
    });
  }
  async ngOnInit() {}

  async dismiss(from?) {
    const closeModal: string = 'close';
    await this.modalController.dismiss(closeModal);
  }

  handleAnchorClick(e) {
    e.preventDefault();
    const url = this.transformYoutubeLinks(e.target.getAttribute('data-video'));
    this.presentPopover(e, url);
  }

  async presentPopover(ev: any, url) {
    const popover = await this.popoverController.create({
      component: VideoPopoverComponent,
      cssClass: 'popover_setting',
      event: ev,
      translucent: true,
      componentProps: { videoUrl: url, youtube: true },
    });
    await popover.present();
  }
  createYoutubeEmbed = (key) => {
    return 'https://www.youtube.com/embed/' + key;
  };

  transformYoutubeLinks = (text) => {
    if (!text) return text;
    const self = this;

    const linkreg = /(?:)<a([^>]+)>(.+?)<\/a>/g;
    const fullreg =
      /(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([^& \n<]+)(?:[^ \n<]+)?/g;
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([^& \n<]+)(?:[^ \n<]+)?/g;

    let resultHtml = text;

    // get all the matches for youtube links using the first regex
    const match = text.match(fullreg);
    if (match && match.length > 0) {
      // get all links and put in placeholders
      const matchlinks = text.match(linkreg);
      if (matchlinks && matchlinks.length > 0) {
        for (var i = 0; i < matchlinks.length; i++) {
          resultHtml = resultHtml.replace(
            matchlinks[i],
            '#placeholder' + i + '#'
          );
        }
      }

      // now go through the matches one by one
      for (var i = 0; i < match.length; i++) {
        // get the key out of the match using the second regex
        let matchParts = match[i].split(regex);
        // replace the full match with the embedded youtube code
        resultHtml = resultHtml.replace(
          match[i],
          self.createYoutubeEmbed(matchParts[1])
        );
      }

      // ok now put our links back where the placeholders were.
      if (matchlinks && matchlinks.length > 0) {
        for (var i = 0; i < matchlinks.length; i++) {
          resultHtml = resultHtml.replace(
            '#placeholder' + i + '#',
            matchlinks[i]
          );
        }
      }
    }

    return resultHtml;
  };
}
