import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AutoLoginGuard } from './guards/auto-login.guard';
import { SubCheckGuard } from './guards/sub-check.guard';

const routes: Routes = [
  {
    path: 'tabs',
    loadChildren: () =>
      import('./primary-tabs/tabs.module').then((m) => m.TabsPageModule),
    canLoad: [SubCheckGuard, AuthGuard],
  },

  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
    canLoad: [AutoLoginGuard],
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'welcome',
    loadChildren: () =>
      import('./pages/welcome/welcome.module').then((m) => m.WelcomePageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'sub-check',
    loadChildren: () =>
      import('./pages/sub-check/sub-check.module').then(
        (m) => m.SubCheckPageModule
      ),
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
