import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Observable, throwError, from } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { TermsService } from './terms.service';
import { StoreService } from './store.service';
import * as moment from 'moment-timezone';
const TOKEN_KEY = environment.TOKEN_KEY;

@Injectable({
  providedIn: 'root',
})

export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private termService: TermsService,
    private store:StoreService
  ) {
    this.init();
  }
  async init() {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    await this.store.init();
    await this.store.openStore("");
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.store.getItem(TOKEN_KEY)).pipe(
      switchMap((token: any) => {
        const tokenV: string = token;
        let request = req;

        if (token) {
          request = req.clone({
            setHeaders: {
              Authorization: `Bearer ${tokenV}`,
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Time-Zone': moment().format('ZZ')
            },
          });
        }

      return next.handle(request).pipe(
          catchError((err: HttpErrorResponse) => {
            console.log(err);

            if (err.status === 401) {
              this.authService.logout();

              this.router.navigateByUrl('/login');
            }
            if (err.status === 403) {
              this.authService.logout();
              this.router.navigateByUrl('/login');
            }

            return throwError(err);
          })
        );
      })
    );
  }
}
