import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
const URL = environment.URL;

@Injectable({
  providedIn: 'root',
})
export class TermsService {
  constructor(private http: HttpClient) {}
  // public interceptorTwo = new BehaviorSubject<boolean>(null);
  private header(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',

      'X-Requested-With': 'XMLHttpRequest',
    });
  }

  getTerms() {
    return this.http.get(`${URL}/api/settings`);
  }
  // getPrivacy() {
  //   this.interceptorTwo.next(true);
  //   return this.http.get(`${URL}/privacy`);
  // }
}
