import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PaymentService } from 'src/app/services/payment.service';
import { PaymentComponent } from '../payment/payment.component';
import * as moment from 'moment';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss'],
})
export class SubscriptionsComponent implements OnInit {
  @Input() realizedPayments: any = [];
  @Input() isLoading: boolean = false;
  @Input() reload: boolean;
  @Output() reloadProfile = new EventEmitter<boolean>();
  constructor(
    public modalController: ModalController,
    public paymentService: PaymentService
  ) {}

  ngOnInit() {
    // this.getPlans();


  }
  getPlans() {
    this.isLoading = true;
    this.paymentService.getPayments().subscribe(
      (res: any) => {
        this.realizedPayments = res.data;
        this.realizedPayments.forEach(payment => {
          payment.fixed=moment.utc(payment.fecha_pago).local().format('DD/MM/YYYY, h:mm a');

      });
        this.isLoading = false;
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }
  reloadProfileModal() {
    this.reloadProfile.emit(true);
  }
  async dismiss() {
    const closeModal: string = 'Modal Closed';
    await this.modalController.dismiss(closeModal);
  }
  async openPayment() {
    const modal = await this.modalController.create({
      component: PaymentComponent,
      cssClass: 'my-custom-class',
    });
    modal.onDidDismiss().then((data) => {
      if (data.data === 'payment') {
        this.getPlans();
        this.reloadProfileModal();
      }
    });
    return await modal.present();
  }
}
