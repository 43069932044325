import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { StoreService } from './store.service';

const URL = environment.URL;
const COUNTRIES_KEY = environment.COUNTRIES_KEY;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly _Countries = new BehaviorSubject(undefined);
  readonly countries = this._Countries.asObservable();
  constructor(private http: HttpClient,
    private store: StoreService) {
      this.init();
    }

  async init() {
    await this.store.init();
    await this.store.openStore("");
  }
  private header(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',

      'X-Requested-With': 'XMLHttpRequest',
    });
  }
  getUser() {
    return this.http.get(`${URL}/api/user`);
  }

  editUser(body) {
    return this.http.put(`${URL}/api/atleta`, body, {
      headers: this.header(),
    });
  }
  userRegister(body) {
    return this.http.post(`${URL}/api/auth/register`, body, {
      headers: this.header(),
    });
  }
  getCountrys() {
    return this.http.get(`${URL}/api/pais`);
  }
  getSettings() {
    return this.http.get(`${URL}/api/settings`, {
      headers: this.header(),
    });
  }
  saveContries(countries) {
    this._Countries.next(countries);

    if (countries) {
      this.store.setItem(COUNTRIES_KEY,  JSON.stringify(countries) );
    } else this.store.removeItem( COUNTRIES_KEY);
  }
  async getStorageCountries() {
    const countries = await this.store.getItem( COUNTRIES_KEY );
    return countries ? JSON.parse(countries) : null;
  }
  deleteStorageCountries() {
    return this.store.removeItem(COUNTRIES_KEY);
  }
}
