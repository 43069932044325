import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ModalController,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import {
  PDFDocumentProxy,
  PDFProgressData,
  PDFSource,
  PdfViewerComponent,
} from 'ng2-pdf-viewer';
import { VideoPlayComponent } from '../video-play/video-play.component';
import { VideoPopoverComponent } from '../video-popover/video-popover.component';

import { Filesystem, Directory } from '@capacitor/filesystem';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { TrainingDescriptionComponent } from '../training-description/training-description.component';
@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardListComponent implements OnInit {
  @Input() cardList: any = [];
  @Input() tableData: any = [];
  @Input() cardImg: boolean = false;
  @Input() from: string = '';
  @Input() lugarImg: any = '';

  currentName: string = '';
  anchors: any;
  spans: any;
  p: any;

  pdfSrc = '../assets/pdf1.pdf';
  error: any;
  page = 1;
  rotation = 0;
  zoom = 1.0;
  originalSize = true;
  pdf: any;
  renderText = true;
  progressData: PDFProgressData;
  isLoaded = false;
  stickToPage = false;
  showAll = false;
  autoresize = true;
  fitToPage = true;
  outline: any[];
  isOutlineShown = false;

  @ViewChild(PdfViewerComponent) private pdfComponent: PdfViewerComponent;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    public modalController: ModalController,
    private changeDetector: ChangeDetectorRef,
    public popoverController: PopoverController,
    public toastController: ToastController,
    private inAppBrowser: InAppBrowser
  ) {}
  ngAfterViewInit(): void {
    this.changeDetector.detectChanges();

    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    this.anchors = this.elementRef.nativeElement.querySelectorAll('a');

    this.anchors.forEach((anchor: HTMLAnchorElement) => {
      anchor.addEventListener('click', (evt) => this.handleAnchorClick(evt));
      anchor.classList.add('nostyle');
    });
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.cardList.firstChange) {

      this.ngAfterViewInit();
    }
    if (this.cardList.length !== 0) {

      this.cardList[0].eg_dias?.forEach((dia) => {
        if (
          !dia.titulo &&
          !dia.descripcion &&
          !dia.url_video &&
          dia.eg_bloques.length === 0
        ) {
          dia.no_data = 'No hay entrenamientos para hoy. Es un día de descanso';
        }


        if (dia.eg_bloques) {
          dia.eg_bloques.forEach((bloque) => {
            bloque.showDescripcion = false;
          });
        }
      });

    }
  }
  async openVideo(url) {
    const modal = await this.modalController.create({
      component: VideoPlayComponent,
      cssClass: 'my-custom-class',
      componentProps: { videoUrl: url, youtube: true },
    });

    // modal.onDidDismiss().then((data) => {

    // });
    return await modal.present();
  }
  createYoutubeEmbed = (key) => {
    return 'https://www.youtube.com/embed/' + key;
  };

  transformYoutubeLinks = (text) => {
    if (!text) return text;
    const self = this;

    const linkreg = /(?:)<a([^>]+)>(.+?)<\/a>/g;
    const fullreg =
      /(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([^& \n<]+)(?:[^ \n<]+)?/g;
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([^& \n<]+)(?:[^ \n<]+)?/g;

    let resultHtml = text;

    // get all the matches for youtube links using the first regex
    const match = text.match(fullreg);
    if (match && match.length > 0) {
      // get all links and put in placeholders
      const matchlinks = text.match(linkreg);
      if (matchlinks && matchlinks.length > 0) {
        for (var i = 0; i < matchlinks.length; i++) {
          resultHtml = resultHtml.replace(
            matchlinks[i],
            '#placeholder' + i + '#'
          );
        }
      }

      // now go through the matches one by one
      for (var i = 0; i < match.length; i++) {
        // get the key out of the match using the second regex
        let matchParts = match[i].split(regex);
        // replace the full match with the embedded youtube code
        resultHtml = resultHtml.replace(
          match[i],
          self.createYoutubeEmbed(matchParts[1])
        );
      }

      // ok now put our links back where the placeholders were.
      if (matchlinks && matchlinks.length > 0) {
        for (var i = 0; i < matchlinks.length; i++) {
          resultHtml = resultHtml.replace(
            '#placeholder' + i + '#',
            matchlinks[i]
          );
        }
      }
    }

    return resultHtml;
  };
  handleAnchorClick(e) {
    e.preventDefault();
    const url = this.transformYoutubeLinks(
      e.target.getAttribute('data-video') ?? e.target.getAttribute('href')
    );
    this.presentPopover(e, url);
  }
  async openDesc(training) {
    const modal = await this.modalController.create({
      component: TrainingDescriptionComponent,
      cssClass: 'my-custom-class',
      componentProps: { info: training, lugarImg: this.lugarImg },
    });

    return await modal.present();
  }
  expandItem(item): void {
    item.loaded = true;

    this.currentName = item.nombre;
    const array = this.cardList[0].eg_dias
      ? this.cardList[0].eg_dias
      : this.cardList;
    if (item.expanded) {
      item.expanded = false;
    } else {
      array.map((card) => {
        if (item == card) {
          card.expanded = !card.expanded;
        } else {
          card.expanded = false;
        }
        return card;
      });
    }
  }

  async presentPopover(ev: any, url) {
    const popover = await this.popoverController.create({
      component: VideoPopoverComponent,
      cssClass: 'popover_setting',
      event: ev,
      translucent: true,
      componentProps: { videoUrl: url, youtube: true },
    });
    await popover.present();
  }

  incrementPage(amount: number) {
    this.page += amount;
  }

  incrementZoom(amount: number) {
    this.zoom += amount;
  }

  /**
   * Get pdf information after it's loaded
   * @param pdf
   */
  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.pdf = pdf;
    this.isLoaded = true;

    this.loadOutline();
  }

  /**
   * Get outline
   */
  loadOutline() {
    this.pdf.getOutline().then((outline: any[]) => {
      this.outline = outline;
    });
  }

  /**
   * Pdf loading progress callback
   * @param {PDFProgressData} progressData
   */
  onProgress(progressData: PDFProgressData) {
    this.progressData = progressData;
    this.isLoaded = false;
    this.error = null; // clear error
  }

  /**
   * Page rendered callback, which is called when a page is rendered (called multiple times)
   *
   * @param {CustomEvent} e
   */
  pageRendered(e: CustomEvent) {
    this.isLoaded = true;
  }

  async downloadPdf(url) {
    const base64Data = await this.readAsBase64(url);
    const fileName = url.substr(url.lastIndexOf('/') + 1);
    const savedFile = await Filesystem.writeFile({
      path: this.currentName + '.pdf',
      data: base64Data,
      directory: Directory.Documents,
    });
    const path = await savedFile.uri;
    if (path) {
      this.presentToast();
      // this.inAppBrowser.create(url, '_system', 'location=yes');
    }
  }

  private async readAsBase64(url) {
    // Fetch the photo, read as a blob, then convert to base64 format
    const response = await fetch(url);
    const blob = await response.blob();

    return (await this.convertBlobToBase64(blob)) as string;
  }
  convertBlobToBase64 = (blob: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });

  private getMymeType(name) {
    if (name.indexOf('pdf') >= 0) return 'aplication/pdf';
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: `Se ha guardado el pdf ${this.currentName} en documentos.`,
      // duration: 10000,
      buttons: [
        {
          icon: 'close-outline',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
      ],
    });
    toast.present();
  }
}
