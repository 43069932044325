import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { StoreService } from './store.service';
const TOKEN_KEY = environment.TOKEN_KEY;
const USER_KEY = environment.USER_KEY;
const URL = environment.URL;
const COUNTRIES_KEY = environment.COUNTRIES_KEY;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // Init with null to filter out the first value in a guard!
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  token = '';
  countrys: any = [];
  private readonly _CurrentUser = new BehaviorSubject(undefined);

  // Expose the observable$ part of the _todos subject (read only stream)
  readonly currentUser$ = this._CurrentUser.asObservable();
  private readonly _Countrys = new BehaviorSubject(undefined);

  // Expose the observable$ part of the _todos subject (read only stream)
  readonly country$ = this._Countrys.asObservable();

  constructor(
    private http: HttpClient,
    public userService: UserService,
    private router: Router,
    private store: StoreService
  ) {

    this.loadToken();

  }
  async init() {
    await this.store.init();
    const echo = await this.store.echo("init store...");
    await this.store.openStore("");
  }

  changeUser(user) {
    this._CurrentUser.next(user);

    if (user) {
      this.store.setItem( USER_KEY,  JSON.stringify(user) );

    } else this.store.removeItem( USER_KEY );
  }
  changeCountrys(country) {
    this._Countrys.next(country);
  }
  private header(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',

      'X-Requested-With': 'XMLHttpRequest',
    });
  }
  async getUser() {
    const user = await this.store.getItem(USER_KEY);
    return user ? JSON.parse(user) : null;
  }

  async loadToken() {
    await  this.init();
    const token = await this.getToken();
    if (token) {
      this.token = token;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
    const user = await this.getUser();
    if (user) {
      this.changeUser(user);
    }
    this.userService.getCountrys().subscribe(
      (res: any) => {
        this.changeCountrys(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
  }
  login(credentials: { email; password; remember }): Observable<any> {
    return this.http.post(`${URL}/api/auth/login`, credentials).pipe(
      map((data: any) => data.data),
      switchMap((data) => {
        this.changeUser(data.user);
        return from(this.store.setItem(TOKEN_KEY, data.token));
      }),
      tap((_) => {
        this.isAuthenticated.next(true);
      })
    );
  }
  verifyEmail(credentials: { email; codigo }) {
    return this.http.post(`${URL}/api/auth/verify`, credentials, {
      headers: this.header(),
    });
  }
  resendCode(credentials: { email; send_verification }) {
    return this.http.post(`${URL}/api/auth/verify`, credentials, {
      headers: this.header(),
    });
  }
  forgotPassword(credentials: { email }): Observable<any> {
    return this.http.post(`${URL}/api/auth/forgot-password`, credentials, {
      headers: this.header(),
    });
  }
  changePassword(credentials: {
    email;
    password;
    password_confirmation;
    token;
  }): Observable<any> {
    return this.http.post(`${URL}/api/auth/change-password`, credentials, {
      headers: this.header(),
    });
  }
  logoutUser(): Observable<any> {
    return this.http.post(`${URL}/api/auth/logout`, {
      headers: this.header(),
    });
  }
  logout(): Promise<void> {
    this.isAuthenticated.next(false);
    this.changeUser(undefined);
    return this.store.removeItem( TOKEN_KEY );
  }
  async getToken() {
    const token = await this.store.getItem(TOKEN_KEY);
    return token;
  }
}
