import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-description-modal',
  templateUrl: './description-modal.component.html',
  styleUrls: ['./description-modal.component.scss'],
})
export class DescriptionModalComponent implements OnInit {
  @Input() info: any = '';
  @Input() from: any = '';
  @Input() nombre: any = '';
  title: String = 'Información';

  constructor(public modalController: ModalController) {}
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.from === 'enfoque'
      ? (this.title = 'Enfoque: ' + this.nombre)
      : (this.title = 'Nivel: ' + this.nombre);
  }
  ngOnInit() {
    this.from === 'enfoque'
      ? (this.title = 'Enfoque: ' + this.nombre)
      : (this.title = 'Nivel: ' + this.nombre);

    if (this.info.faqs)
      this.info.faqs.forEach((element) => {
        element.expanded = false;
      });
  }
  expandItem(item) {
    const array = this.info.faqs;

    if (item.expanded) {
      item.expanded = false;
    } else {
      array.map((card) => {
        if (item == card) {
          card.expanded = !card.expanded;
        } else {
          card.expanded = false;
        }
        return card;
      });
    }
  }
  async dismiss(from?) {
    const closeModal: string = from ? from : 'close';
    await this.modalController.dismiss(closeModal);
  }
}
