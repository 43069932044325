import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
const URL = environment.URL;

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private http: HttpClient) {}

  private header(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',

      'X-Requested-With': 'XMLHttpRequest',
    });
  }
  getPayments() {
    return this.http.get(`${URL}/api/pagos`, {
      headers: this.header(),
    });
  }
  registerPayment(body) {
    return this.http.post(`${URL}/api/plan-suscripcion`, body, {
      headers: this.header(),
    });
  }
  flow(body) {
    return this.http.post(`${URL}/api/flow`, body, {
      headers: this.header(),
    });
  }
  transbank(body) {
    return this.http.post(`${URL}/api/transbank`, body, {
      headers: this.header(),
    });
  }
  getSubsPlans(type: number) {
    var uri = `/api/plan-suscripcion?tipo=${type}`;
    var res = encodeURI(uri);
    return this.http.get(`${URL}${res}`, {
      headers: this.header(),
    });
  }
}
