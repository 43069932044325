import { Component, Input, OnInit } from '@angular/core';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { ToastController } from '@ionic/angular';
import { PDFDocumentProxy, PDFProgressData } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnInit {
  @Input() recurso;
  privacy: any;
  error: any;
  page = 1;
  rotation = 0;
  zoom = 1.0;
  originalSize = true;
  pdf: any;
  renderText = true;
  progressData: PDFProgressData;
  isLoaded = false;
  stickToPage = false;
  showAll = false;
  autoresize = true;
  fitToPage = true;
  outline: any[];
  isOutlineShown = false;
  constructor(public toastController: ToastController) {}

  ngOnInit() {}

  incrementPage(amount: number) {
    this.page += amount;
  }

  incrementZoom(amount: number) {
    this.zoom += amount;
  }

  /**
   * Get pdf information after it's loaded
   * @param pdf
   */
  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.pdf = pdf;
    this.isLoaded = true;

    this.loadOutline();
  }

  /**
   * Get outline
   */
  loadOutline() {
    this.pdf.getOutline().then((outline: any[]) => {
      this.outline = outline;
    });
  }

  /**
   * Pdf loading progress callback
   * @param {PDFProgressData} progressData
   */
  onProgress(progressData: PDFProgressData) {
    this.progressData = progressData;
    this.isLoaded = false;
    this.error = null; // clear error
  }

  /**
   * Page rendered callback, which is called when a page is rendered (called multiple times)
   *
   * @param {CustomEvent} e
   */
  pageRendered(e: CustomEvent) {
    this.isLoaded = true;
  }

  async downloadPdf(url) {
    const base64Data = await this.readAsBase64(url);
    const fileName = url.substr(url.lastIndexOf('/') + 1);
    const savedFile = await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Documents,
    });
    const path = await savedFile.uri;
    if (path) {
      this.presentToast();
      // this.inAppBrowser.create(url, '_system', 'location=yes');
    }
  }

  private async readAsBase64(url) {
    // Fetch the photo, read as a blob, then convert to base64 format
    const response = await fetch(url);
    const blob = await response.blob();

    return (await this.convertBlobToBase64(blob)) as string;
  }
  convertBlobToBase64 = (blob: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });

  private getMymeType(name) {
    if (name.indexOf('pdf') >= 0) return 'aplication/pdf';
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: 'Se ha guardado el pdf en documentos.',
      duration: 2000,
    });
    toast.present();
  }
}
